import { useEffect, useState } from 'react'
import { Cursor, useTypewriter } from 'react-simple-typewriter'
import Loader from 'react-loaders'
import { useNavigate } from 'react-router-dom' // <— We'll use 'useNavigate' for the button
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'

import './index.scss' // Our SCSS

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [showTypewriter, setShowTypewriter] = useState(false)

  const introArray = "I'm James".split('')

  const [text] = useTypewriter({
    words: ['Student', 'Software Engineer', 'Gamer', 'Problem-Solver', 'Traveler', ''],
    loop: true,
    delaySpeed: 3000,
    typeSpeed: 125,
    deleteSpeed: 100,
  })

  // for routing from a button
  const navigate = useNavigate()

  const handleContactClick = () => {
    navigate('/contact')
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    const timer2 = setTimeout(() => {
      setShowTypewriter(true)
    }, 5000)

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [])

  const renderTypewriterWithHover = (typedText) =>
    typedText.split('').map((char, index) => (
      <span
        key={index}
        className="text-animate-hover typewriter"
        style={{ display: 'inline-block' }}
      >
        {char}
      </span>
    ))

  return (
    <>
      <div className="container home-page">
        {/* TEXT ZONE */}
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i</span>
            <span className={`${letterClass} _13`}>,</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={introArray}
              idx={22}
            />
            <br />
            <span
              style={{
                display: 'inline-block',
                minWidth: '10ch', // ensures space for typewriter
              }}
            >
              {showTypewriter && renderTypewriterWithHover(text)}
              <Cursor cursorStyle="|" cursorColor="dark gray" />
            </span>
          </h1>
          <h2>Learning | Building | Deploying</h2>

          {/* REAL BUTTON that navigates to "/contact" */}
          <button className="flat-button" onClick={handleContactClick}>
            CONTACT ME
          </button>
        </div>

        {/* LOGO CONTAINER (so we can control overflow) */}
        <div className="logo-container">
          <Logo />
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Home
